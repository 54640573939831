.signature {
  border: 1px solid black;
  width: 40%;
  height: 150px;
}

.container {
  border: 1px solid black;
  width: 60%;
  margin: auto;
  background-color: white;
}


